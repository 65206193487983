import { Component } from 'nuxt-property-decorator';
import PaletteMixin from '@/mixins/paletteMixin';

@Component
export default class PaletteStylesMixin extends PaletteMixin {
    beforeMount() {
        const style =
            document.querySelector('#palette-styles') || document.createElement('style');
        style.setAttribute('id', '#palette-styles');
        style.setAttribute('type', 'text/css');

        style.innerHTML = `
                .palette-background-bg,
                .palette-background-bg-hover:hover {
                    background: ${this.backgroundBg['background-color']}
                }
                .palette-background-icon,
                .palette-background-icon-hover:hover {
                    background: ${this.backgroundIcon['background-color']}
                }
                .palette-background-trigger,
                .palette-background-trigger-hover:hover {
                    background: ${this.backgroundTrigger['background-color']} !important
                }
                .palette-background-text,
                .palette-background-text-hover:hover {
                    background: ${this.backgroundText['background-color']}
                }
                .palette-border-bg,
                .palette-border-bg-hover:hover {
                    border-color: ${this.borderBg['border-color']} 
                }
                .palette-border-icon,
                .palette-border-icon-hover:hover {
                    border-color: ${this.borderIcon['border-color']} 
                }
                .palette-border-trigger,
                .palette-border-trigger-hover:hover {
                    border-color: ${this.borderTrigger['border-color']} !important
                }
                .palette-border-text,
                .palette-border-text-hover:hover {
                    border-color: ${this.borderText['border-color']}
                }
                .palette-color-bg, 
                .palette-color-bg-hover:hover {
                    color: ${this.colorBg['color']}
                }
                .palette-color-icon, 
                .palette-color-icon-hover:hover {
                    color: ${this.colorIcon['color']}
                }
                .palette-color-trigger, 
                .palette-color-trigger-hover:hover {
                    color: ${this.colorTrigger['color']}
                }
                .palette-color-text, 
                .palette-color-text-hover:hover,
                a[rel="noopener noreferrer"],
                a[rel="noopener noreferrer nofollow"],
                a, a:hover {
                    color: ${this.colorText['color']}
                }                                                
                .child-svg-stroke-palette-text-hover:hover .palette-stroke-text {
                    stroke: ${this.colorText['color']}
                }
                @media screen and (max-width: 767px) {
                    .palette-background-bg-sm,
                    .palette-background-bg-sm-hover:hover {
                        background: ${this.backgroundBg['background-color']}
                    }
                }
        `;
        // @ts-ignore
        document.querySelector('head').appendChild(style);
    }
}
